import React, { useState, useEffedt, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Formik, FormikProps } from 'formik';
import useCampaigns from '../../context/CampaignContext';
import {
    Box,
    Button,
    Grid,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { API_URL } from '../../constants';
import {
    ArrowBack as ArrowBackIcon, FormatShapes,
    Fullscreen as FullScreenIcon,
    FullscreenExit as FullScreenExitIcon,
    Send as SendIcon,
    InsertDriveFile as FileUploadIcon,
    Check as CheckIcon,
    FileCopy as CopyIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import FileUpload from '../../components/Global/FileUpload';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditNpoContact from './EditNpoContact';
import { Route, Switch } from 'react-router-dom';
import { AddCircle as CircleIcon } from '@material-ui/icons';
import { useStyles as TableStyles } from "../../themes/TableStyles";
import { useCSVReader } from 'react-papaparse';
import CsvResults from './CsvResults';
import { CSVLink, CSVDownload } from "react-csv";


const styles = {
    csvReader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    browseFile: {
        padding: 15,
    },
    acceptedFile: {
        border: '1px solid #ccc',
        height: 45,
        lineHeight: 2.5,
        paddingLeft: 10,
        width: '80%',
    },
    remove: {
        borderRadius: 0,
        padding: '0 20px',
    },
    progressBarBackgroundColor: {
        backgroundColor: 'green',
    },
};


function EditNpo() {
    const { campaigns, getCampaigns } = useCampaigns();
    const { hash } = useParams();
    const [npo, setNpo] = useState();
    const [serverError, setServerError] = useState('');
    const classes = useStyles();
    const tableClass = TableStyles();
    const history = useHistory();
    const [image, setImage] = useState();
    const [npoCampaigns, setNpoCampaigns] = useState();
    const localStorageData = localStorage.getItem('Member for a Day');
    const USER = JSON.parse(localStorageData).user;
    const [createContact, setCreateContact] = useState(false);
    const { CSVReader } = useCSVReader();
    const [showCsvImport, setShowCsvImport] = useState(false);
    const [csvData, setCsvData] = useState();
    const [importedData, setImportedData] = useState();
    const [submittingCsv, setSubmittingCsv] = useState(false);
    const [csvJoinMessage, setCsvJoinMessage] = useState('');
    const [fullScreen, setFullScreen] = useState(false);
    const [isCvc, setIsCvc] = useState(false);
    const [isCertificate, setIsCertificate] = useState(false);
    const [isHandlingFulfilment, setIsHandlingFulfilment] = useState(true);
    const [showEmailWarning, setShowEmailWarning] = useState(false);
    const [successfulFlowStart, setSuccessfulFlowStart] = useState([]);
    const [batchFlowFinished, setBatchFlowFinished] = useState();
    const [failedFlowStart, setFailedFlowStart] = useState([]);
    const [ineligibleCampaigns, setIneligibleCampaigns] = useState([]);
    const [loading, setLoading] = useState(false)
    const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
    const [canDeleteNpo, setCanDeleteNpo] = useState(false)
    const [copied, setCopied]= useState(false)

    const startEmailFlow = async (campaign, isFirst) => {
        let winnerLink = `https://scheduling.memberforaday.com/customer/${campaign.hash}`;
        let hostLink = `https://scheduling.memberforaday.com/dateselection/${campaign.hash}?hostId=${campaign.hosts[0].id}`;
        let winnerReschedule = `https://scheduling.memberforaday.com/dateselection/${campaign.hash}?winnerId=${campaign.winners[0].id}`;

        const d = new Date()
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        const eDate = new Date(year + 1, month, day);

        function formatDate(d) {
            var year = d.getFullYear();
            var month = ("0" + (d.getMonth() + 1)).slice(-2);
            var day = ("0" + d.getDate()).slice(-2);
            return year + "-" + month + "-" + day;
        }
        let expirationDate = formatDate(eDate)

        let isCertificate
        if (campaign.hasCertificate === 1 || campaign.donationType === 'certificate') {
            isCertificate = 'true'
        } else {
            isCertificate = 'false'
        }
        let formValues = {
            flowDirection: campaign.flow,
            hash: campaign.hash,
            cioId: campaign.cioId,
            rallyUpCampaignId: campaign.rallyUpItemId,
            winnerId: campaign.winners[0].id,
            hostId: campaign.hosts[0].id,
            winnerEmail: campaign.winners[0].email,
            hostEmail: campaign.hosts[0].contactPersonEmail ? campaign.hosts[0].contactPersonEmail : campaign.hosts[0].email,
            eventType: 'rallyup_campaign_ended',
            winnerSelectedDates: (campaign.winners[0].dates.length > 0 || campaign?.hosts[0]?.dates.filter(d => d.isSelected > 0 || d.isFinalDate > 0).length > 0) ? 'true' : 'false',
            hostSelectedDates: (campaign.hosts[0].dates.length > 0 || campaign?.winners[0]?.dates.filter(d => d.isSelected > 0 || d.isFinalDate > 0).length > 0) ? 'true' : 'false',
            npoContactEmail: campaign.npo?.contacts[0]?.email,
            npoContactName: campaign.npo?.contacts[0]?.name,
            winnerName: campaign.winners[0].name,
            hostName: campaign.hosts[0].name,
            npoName: campaign.npo?.name,
            locationName: campaign.location?.name,
            winnerLink: winnerLink,
            hostLink: hostLink,
            winnerReschedule: winnerReschedule,
            campaignName: campaign.name,
            npoImage: campaign.npo.image,
            endDate: d.toDateString(),
            expirationDate: expirationDate,
            guestName1: campaign.winners[0].guests[0]?.name,
            guestName2: campaign.winners[0].guests[1]?.name,
            guestName3: campaign.winners[0].guests[2]?.name,
            guestName4: campaign.winners[0].guests[3]?.name,
            isCvc: campaign.isCVC === 1 ? true : false,
            isHandlingFulfilment: campaign.directConnect === 1 ? 'false' : 'true',
            isCertificate: isCertificate,
            isFirst: isFirst ? 'true' : 'false',
        }
        try {
            let response = await axios.post(`${API_URL}/start-email-flow`, formValues)
            if (response.data.status === 'success') {
                // setSuccessfulFlowStart([...successfulFlowStart, campaign])
                return 'success'
            } else if (response.data.status === 'failed') {
                console.log('Failed to start email flow for ', campaign.name)
                return 'failed'
                // setFailedFlowStart([...failedFlowStart, campaign])
            }
        } catch (e) {
            console.log('Failed to update data with error: ', e)
            // setFailedFlowStart([...failedFlowStart, campaign])
            return 'failed'
        }
    }

    const startBatchEmailFlow = () => {
        let failedStart = []
        let successfulStart = []
        const trigger = async (c, isFirst) => {
            const result = await startEmailFlow(c, isFirst)
            return [result, c]
        }
        let ineligible = []
        let triggers = []
        for (const c of npoCampaigns) {
            if (
                c.winners.length > 0 &&
                c.hosts.length > 0 &&
                // c.rallyUpItemId !== '' && 
                // c.rallyUpItemId !== undefined &&
                c.emailFlowStarted === 0
            ) {
                let isFirst = false;
                if (npoCampaigns.indexOf(c) === 0) {
                    isFirst = true;
                }
                triggers.push(trigger(c, isFirst))
            } else if (c.emailFlowStarted === 0) {
                ineligible = [...ineligible, c]
            }
        }
        setLoading(true)
        Promise.all(triggers).then((values) => {
            for (const v of values) {
                if (v[0] === 'success') {
                    successfulStart.push(v[1])
                } else {
                    failedStart.push(v[1])
                }
            }
            setSuccessfulFlowStart(successfulStart)
            setFailedFlowStart(failedStart)
            setLoading(false)
            getCampaigns()
        }).catch((error) => {
            console.log('Failed to update data with error: ', error)
        });

        setIneligibleCampaigns(ineligible)
        setBatchFlowFinished(true)
    }


    const associateCsvData = async () => {
        setSubmittingCsv(true)
        let successfulUpdates = []
        let failedUpdates = []
        const promises = importedData.map(async campaign => {
            const winner = {
                name: campaign.winners[0].name,
                email: campaign.winners[0].email,
                phone: campaign.winners[0].phone,
                campaignId: campaign.id,
            }
            const response = await axios.post(`${API_URL}/addwinnertocampaign`, winner)
            if (response.data.status === 'success') {
                successfulUpdates.push(campaign.name)
            } else {
                failedUpdates.push(campaign.name)
            }
        })
        try {
            await Promise.all(promises)
            if (successfulUpdates.length === importedData.length && failedUpdates.length === 0) {
                setCsvJoinMessage('Successfully joined winner data with campaigns')
            } else {
                setCsvJoinMessage('Error while updating the following campains: ', failedUpdates.map(u => u + ', '))
            }
            getCampaigns()
        } catch (error) {
            setCsvJoinMessage(`Error saving winner data: ${error.status + ' ' + error.statusText + '\n' + JSON.stringify(error.data)}. ${failedUpdates.length > 0 ? 'The following campaigns were effected: ' + failedUpdates.map(u => u + ', ') : ''}`)
        }
        setSubmittingCsv(false)
    }

    const setImportData = (data) => {
        setImportedData(data)
    }

    useEffect(() => {
        // var approved = campaigns?.filter(n => n.npo != null).filter(n => n.npo.id == USER.npoId).find(n => n.npo.hash)
        // if(USER.role == 'NPO' & hash != approved?.npo.hash){
        //     history.push('/app/error');
        // } else {
        //     setNpo(campaigns.filter(f => f.npo != undefined).map(campaign => campaign.npo).find(c => c.hash === hash));
        // }
        if (hash) {
            const getNpo = async () => {
                var response = await axios.get(`${API_URL}/npo/${hash}`)
                setNpo(response.data.response)
            }
            getNpo();
        }
    }, [campaigns, hash])

    useEffect(() => {
        if (npo)
            setNpoCampaigns(campaigns.filter(f => f.npo != undefined).filter(n => n.npo.id == npo.id));
    }, [npo])

    const initialValues = {
        id: npo?.id || '',
        name: npo?.name || '',
        campaignName: npo?.campaignName || '',
        image: npo?.image || '',
        hash: npo?.hash || '',
        contacts: npo?.contacts || [],
        rallyUpId: npo?.rallyUpId || '',
        hostLink: "https://scheduling.memberforaday.com/host/" + npo?.hash
    }

    function handleUpload(event) {
        if (event.target.files[0] != null) {
            setImage(event.target.files[0])
        }
    }

    const ImageThumb = ({ image, values }) => {
        return (
            <>
                {image === '' ?
                    <img src={npo?.image} className={classes.fileImage} style={{ maxWidth: '300px', objectFit: 'cover' }} alt={image.name} />
                    :
                    <img src={URL.createObjectURL(image)} className={classes.fileImage} style={{ maxWidth: '300px', objectFit: 'cover' }} alt={image.name} />
                }
            </>
        );
    };

    const csvImport = () => {
        setShowCsvImport(true)
    }

    const submitData = async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true)

        const formValues = {
            name: values.name,
            campaignName: values.campaignName,
            rallyUpId: values.rallyUpId,
        }

        const imageValue = {
            image: image
        }

        var time = new Date()

        let data = new FormData();
        data.append('name', values.name)
        data.append('campaignName', values.campaignName)
        data.append('image', image)
        data.append('rallyUpId', values.rallyUpId)

        if (!hash) {
            if (!createContact) {
                try {
                    await axios.post(`${API_URL}/new/npo`, data)
                    getCampaigns();
                } catch (e) {
                    console.log('Failed to update data with error: ', e)
                }
                setSubmitting(false);
                history.goBack();
            } else {
                try {
                    await axios.post(`${API_URL}/new/npo`, data).then((response) => {
                        setSubmitting(false);
                        history.push(`/app/npos/edit/${response.data.response.hash}/contact`)
                    })
                    getCampaigns();
                } catch (e) {
                    console.log('Failed to update data with error: ', e)
                }
            }
        } else {
            if (!createContact) {
                try {
                    await axios.post(`${API_URL}/npo/${values.hash}`, data)
                    getCampaigns();
                } catch (e) {
                    console.log('Failed to update data with error: ', e)
                }
                setSubmitting(false);
                history.goBack();
            } else {
                try {
                    await axios.post(`${API_URL}/npo/${values.hash}`, data).then((response) => {
                        setSubmitting(false);
                        history.push(`/app/npos/edit/${response.data.response.hash}/contact`)
                    })
                    getCampaigns();
                } catch (e) {
                    console.log('Failed to update data with error: ', e)
                }
            }

        }
    }

    const deleteNpo = async (values) => {
        let data = new FormData();
        data.append('hash', values.hash)
        try {
            let response = await axios.post(`${API_URL}/npo-delete`, data)
            if (response.data.status === 'success') {
                console.log('npo deleted')
                history.goBack();
            }
        } catch (error) {
            console.error(error)
        }
    }

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true)

    setTimeout(()=> {
        setCopied(false)
    }, 1000)
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        submitData(values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        })
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setServerError(err.message);
                        setSubmitting(false);
                    }
                    resetForm();
                }}
            >
                {(form) => {
                    return (
                        <form
                            onSubmit={form.handleSubmit}
                        >
                            <Paper>
                                <Dialog
                                    fullScreen={false}
                                    open={showEmailWarning}
                                    onClose={() => setShowEmailWarning(false)}
                                    aria-labelledby="responsive-dialog-title2"
                                // fullWidth
                                >
                                    {!batchFlowFinished ? (
                                        <>
                                            <DialogTitle id="responsive-dialog-title2">
                                                Are you sure you want to start the email flow for this campaign?
                                            </DialogTitle>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => setShowEmailWarning(false)}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        startBatchEmailFlow()
                                                    }}
                                                >
                                                    Start Email Flow
                                                </Button>
                                            </DialogActions>
                                        </>
                                    ) : null}
                                    {batchFlowFinished ? (
                                        <>
                                            <DialogTitle id="responsive-dialog-title2">
                                                {loading ? "Hang tight, email flows starting" : "Batch Email Flow Start has finished!"}
                                            </DialogTitle>
                                            <DialogContent>
                                                {loading ?
                                                    <Typography>
                                                        When the campaigns have started the results will appear here
                                                    </Typography>
                                                    :
                                                    (
                                                        <>
                                                            <Typography>
                                                                Campaigns successfully started:
                                                            </Typography>
                                                            <Paper>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>Name</TableCell>
                                                                            <TableCell>Hash</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {successfulFlowStart?.map(c => (
                                                                            <TableRow>
                                                                                <TableCell>{c.name}</TableCell>
                                                                                <TableCell>{c.hash}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </Paper>
                                                            {ineligibleCampaigns && ineligibleCampaigns.length > 0 ?
                                                                <div>
                                                                    <div style={{ padding: '20px' }} />
                                                                    <Typography>
                                                                        The Following Campaigns cannot start the email flow due to missing data:
                                                                    </Typography>
                                                                    <Paper>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell>Name</TableCell>
                                                                                    <TableCell>Hash</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {ineligibleCampaigns?.map(c => (
                                                                                    <TableRow style={{ cursor: 'pointer' }} onClick={() => { window.open(`/app/campaigns/${c.hash}`, "_blank") }}>
                                                                                        <TableCell>{c.name}</TableCell>
                                                                                        <TableCell>{c.hash}</TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Paper>
                                                                </div>
                                                                : null}
                                                            {failedFlowStart && failedFlowStart.length > 0 ?
                                                                <div>
                                                                    <div style={{ padding: '20px' }} />
                                                                    <Typography>
                                                                        Campaigns failed to start:
                                                                    </Typography>
                                                                    <Paper>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell>Name</TableCell>
                                                                                    <TableCell>Hash</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {failedFlowStart?.map(c => (
                                                                                    <TableRow style={{ cursor: 'pointer' }} onClick={() => { window.open(`/app/campaigns/${c.hash}`, "_blank") }}>
                                                                                        <TableCell>{c.name}</TableCell>
                                                                                        <TableCell>{c.hash}</TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Paper>
                                                                </div>
                                                                : null}
                                                        </>
                                                    )
                                                }
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => setShowEmailWarning(false)}
                                                    color="primary"
                                                >
                                                    OK
                                                </Button>
                                            </DialogActions>
                                        </>
                                    ) : null}
                                </Dialog>
                                <Dialog
                                        fullScreen={fullScreen}
                                        open={showCsvImport}
                                        onClose={() => { setShowCsvImport(false); setCsvData() }}
                                        aria-labelledby="responsive-dialog-title"
                                        fullWidth
                                        maxWidth={csvData ? 'lg' : 'sm'}
                                    >
                                        <Dialog
                                            fullScreen={false}
                                            open={csvJoinMessage !== ''}
                                            onClose={() => { setCsvJoinMessage('') }}
                                            aria-labelledby="responsive-dialog-title"
                                            fullWidth
                                        >
                                            <DialogContent>
                                                <Typography>{csvJoinMessage}</Typography>
                                            </DialogContent>
                                            <DialogActions >
                                                <Button
                                                    color="primary"
                                                    onClick={() => { setCsvJoinMessage(''); setCsvData(); setShowCsvImport(false) }}
                                                >
                                                    Ok
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        <DialogTitle id="responsive-dialog-title">
                                            Import CSV
                                        </DialogTitle>
                                        <Button
                                            onClick={() => { setFullScreen(!fullScreen) }}
                                            color="primary"
                                            style={{ position: 'absolute', top: 20, right: 20 }}
                                        >
                                            {fullScreen ? (
                                                <FullScreenExitIcon />
                                            ) : (
                                                <FullScreenIcon />
                                            )}
                                        </Button>
                                        <DialogContent>
                                            {!csvData ? (
                                                <></>
                                            ) : (
                                                <>
                                                    {npoCampaigns ?
                                                        <CsvResults npoCampaigns={npoCampaigns} csvData={csvData} setImportData={setImportData} />
                                                        : null}
                                                </>
                                            )}
                                        </DialogContent>
                                        {csvData ? (

                                            <DialogActions >
                                                <Button
                                                    onClick={() => { setShowCsvImport(false); setCsvData(); }}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    disabled={submittingCsv}
                                                    onClick={() => {
                                                        associateCsvData();
                                                    }}
                                                >
                                                    Save Winner Data
                                                </Button>
                                            </DialogActions>

                                        ) : null}
                                    </Dialog>
                                    <Dialog
                                        open={displayDeleteDialog}
                                        onClose={() => {setDisplayDeleteDialog(false); setCanDeleteNpo(false);}}
                                    >
                                        <DialogTitle>
                                            Are you sure you want to delete this npo? 
                                            <br></br>
                                            {"(You can not undo this action)"}
                                            <br></br>
                                            <br></br>
                                            Please type the name of the NPO to continue with deletion:
                                            <br></br>
                                            {form.values.name}
                                            <br></br>
                                            <TextField id="outlined-basic" label="" variant="outlined" style={{width: '100%'}}
                                                onChange={(e) => {
                                                    if (e.target.value === form.values.name) {
                                                        setCanDeleteNpo(true)
                                                    } else {
                                                        setCanDeleteNpo(false)
                                                    }
                                                }}
                                            />
                                        </DialogTitle>
                                        <DialogActions>
                                            <Button onClick={() => {setDisplayDeleteDialog(false); setCanDeleteNpo(false);}}>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => deleteNpo(form.values)} disabled={!canDeleteNpo}>
                                                OK
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                <Grid
                                    container
                                    justifyContent={'space-between'}
                                    style={{ position: 'relative' }}
                                >
                                    <Grid item xs={6}>
                                        <DialogTitle id="responsive-dialog-title">
                                            <IconButton
                                                color="inherit"
                                                onClick={() => history.push('/app/npos')}
                                                className={classNames(
                                                    classes.headerMenuButton,
                                                    classes.headerMenuButtonCollapse
                                                )}
                                                style={{ marginLeft: '0px' }}
                                            >
                                                <ArrowBackIcon
                                                    classes={{
                                                        root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                    }}
                                                />
                                            </IconButton>
                                            {hash ? 'Edit NPO' : 'Create NPO'}
                                        </DialogTitle>
                                    </Grid>
                                        <Grid container spacing={3} style={{paddingLeft: '20px', paddingRight: '20px'}}>
                                            <Grid item xs={12} md={6}>
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <FileUpload onDrop={handleUpload} onChange={handleUpload} file={image} ImageThumb={ImageThumb} accept={"image/jpeg, image/png"} />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6} >
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    {/* <img style={{position: 'relative', left: '50%', transform: 'translateX(-50%)'}} src={form.values.image} /> */}

                                                    <TextField
                                                        error={Boolean(form.touched.duration && form.errors.duration)}
                                                        fullWidth
                                                        helperText={form.touched.duration && form.errors.duration}
                                                        label="Name"
                                                        name="name"
                                                        type="text"
                                                        onBlur={form.handleBlur}
                                                        onChange={form.handleChange}
                                                        value={form.values.name}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    {/* <img style={{position: 'relative', left: '50%', transform: 'translateX(-50%)'}} src={form.values.image} /> */}

                                                    <TextField
                                                        error={Boolean(form.touched.duration && form.errors.duration)}
                                                        fullWidth
                                                        helperText={form.touched.duration && form.errors.duration}
                                                        label="Campaign Name"
                                                        name="campaignName"
                                                        type="text"
                                                        onBlur={form.handleBlur}
                                                        onChange={form.handleChange}
                                                        value={form.values.campaignName}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    {/* <img style={{position: 'relative', left: '50%', transform: 'translateX(-50%)'}} src={form.values.image} /> */}

                                                    <TextField
                                                        error={Boolean(form.touched.duration && form.errors.duration)}
                                                        fullWidth
                                                        helperText={form.touched.duration && form.errors.duration}
                                                        label="RallyUp Id"
                                                        name="rallyUpId"
                                                        type="text"
                                                        onBlur={form.handleBlur}
                                                        onChange={form.handleChange}
                                                        value={form.values.rallyUpId}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                                {hash ? (
                                                    <Box
                                                        mt={3}
                                                        mb={1}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={11}>
                                                                <TextField
                                                                    disabled
                                                                    fullWidth
                                                                    label={form.values.name + " Host link"}
                                                                    name="hostLink"
                                                                    type="text"
                                                                    onBlur={form.handleBlur}
                                                                    value={form.values.hostLink}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Button onClick={() => copyText(form.values.hostLink)} style={{width: 'calc(100% - 10px)', marginLeft: '10px'}}>
                                                                    {copied ? (
                                                                        // <Typography style={{color: 'green'}}>Copied!</Typography>
                                                                        <CheckIcon />
                                                                    ):<CopyIcon style={{margin: 10}} />}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ): null}
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <CSVLink style={{textDecoration: 'none'}} filename={`${npo?.name}_data.csv`} data={campaigns && campaigns.filter(f => f.npoId === npo?.id).map(c => {
                                                        return {
                                                            name: c.name, 
                                                            locationName: c.location?.name,
                                                            locationAddress: c.location?.address,
                                                            numberOfAllowedGuests: c.numberOfAllowedGuests,
                                                            roundsOfGolf: c.roundsOfGolf,
                                                            addonsSelected: c.addons,
                                                            roundAttributes: c.roundAttributes,
                                                            hostASecondRound: c.hostASecondRound,
                                                            cashCost: c.cashCost,
                                                            packageDescription: c.packageDescription?.replaceAll('"', ''),
                                                            bookingRestrictions: c.bookingRestrictions,
                                                            winnerName: c.winners?.length > 0 && c.winners[0]?.name,
                                                            winnerEmail: c.winners?.length > 0 && c.winners[0]?.email,
                                                            winnerPhone: c.winners?.length > 0 && c.winners[0]?.phone,
                                                            hostName: c.hosts?.length > 0 && c.hosts[0]?.name,
                                                            hostEmail: c.hosts?.length > 0 && c.hosts[0]?.email,
                                                            hostPhone: c.hosts?.length > 0 && c.hosts[0]?.phone,
                                                            hostContactName: c.hosts?.length > 0 && c.hosts[0]?.contactPersonName,
                                                            hostContactEmail: c.hosts?.length > 0 && c.hosts[0]?.contactPersonEmail,
                                                            hostContactPhone: c.hosts?.length > 0 && c.hosts[0]?.contactPersonPhone,
                                                            hash: c.hash,
                                                            attributesPaidBy: c.attributesPaidBy,
                                                            // includesMeal: c.includesMeal,
                                                            // includesCaddies: c.includesCaddies,
                                                            clubRules: c.clubRules?.replaceAll('"', ''), 
                                                            dates: c.winner_dates?.length > 0 ? c.winner_dates.map(d => {
                                                                return `date: ${d.date}, confirmed: ${d.isFinalDate}, rainDate: ${d.isSelected}
                                                                `
                                                            })
                                                            :
                                                            c.host_dates.map(d => {
                                                                return `date: ${d.date}, confirmed: ${d.isFinalDate}, rainDate: ${d.isSelected}`
                                                            }),
                                                            
                                                        }
                                                    })}><Button style={styles.browseFile}>Download data as CSV</Button></CSVLink>
                                                </Box>
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }}>
                                                        <TableContainer>
                                                            <Box
                                                                mt={3}
                                                                mb={1}
                                                            >
                                                                <Typography variant={'h4'}>Contacts</Typography>
                                                            </Box>
                                                            <Table aria-label="simple table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell align="left">Email</TableCell>
                                                                        <TableCell align="left"></TableCell>
                                                                        <TableCell align="left"></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {form.values.contacts.map((c, key) => {
                                                                        return (
                                                                            <TableRow className={classes.cell} key={key} >
                                                                                <TableCell component="th" scope="row">{c.name}</TableCell>
                                                                                <TableCell align="left">{c.email}</TableCell>
                                                                                <TableCell align="left" >
                                                                                    <Button onClick={() => {
                                                                                        history.push(`/app/npos/edit/${form.values.hash}/contact/${c.id}`)
                                                                                    }}>
                                                                                        Edit
                                                                                    </Button>
                                                                                </TableCell>
                                                                                <TableCell align="left" >
                                                                                    <Button onClick={async () => {
                                                                                        await axios.get(`${API_URL}/delete/npo-contact/${c.id}`);
                                                                                        getCampaigns();
                                                                                    }}>
                                                                                        Delete
                                                                                    </Button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                    <Button style={{ paddingTop: '20px', paddingBottom: '20px' }} onClick={() => {
                                                                        setCreateContact(true); form.submitForm();
                                                                    }}>
                                                                        <CircleIcon />
                                                                    </Button>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Paper>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        {hash &&
                                            <Grid item xs={12} style={{paddingRight: '20px', paddingLeft: '20px'}}>
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }}>
                                                        <TableContainer className={tableClass.container}>
                                                            <Box
                                                                mt={3}
                                                                mb={1}
                                                            >
                                                                <Typography variant={'h4'}>Campaigns</Typography>
                                                            </Box>
                                                            <Grid container style={{ position: 'relative' }}>
                                                                <Grid item xs={12} md={6}>
                                                                    <CSVReader
                                                                        onUploadAccepted={(results) => {
                                                                            setCsvData(results);
                                                                            csvImport()
                                                                        }}
                                                                    >
                                                                        {({
                                                                            getRootProps,
                                                                            acceptedFile,
                                                                            ProgressBar,
                                                                            getRemoveFileProps,
                                                                        }) => (
                                                                            <>
                                                                                <div style={styles.csvReader}>
                                                                                    <Button  {...getRootProps()} style={styles.browseFile}>
                                                                                        Import Winners from CSV
                                                                                        <FileUploadIcon
                                                                                            style={{ marginLeft: 10 }}
                                                                                        />
                                                                                    </Button>
                                                                                    {/* <div style={styles.acceptedFile}>
                                                                            {acceptedFile && acceptedFile.name}
                                                                            </div> */}
                                                                                    {/* <button {...getRemoveFileProps()} style={styles.remove}>
                                                                            Remove
                                                                            </button> */}
                                                                                </div>
                                                                                <ProgressBar style={styles.progressBarBackgroundColor} />
                                                                            </>
                                                                        )}
                                                                    </CSVReader>
                                                                </Grid>
                                                                <Grid item xs={12} md={6} style={{ position: 'absolute', right: 0 }}>
                                                                    <Button style={styles.browseFile} onClick={() => setShowEmailWarning(true)}>
                                                                        Batch Start Email Flow
                                                                        <SendIcon
                                                                            style={{ marginLeft: 10 }}
                                                                            classes={{
                                                                                root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                                            }}
                                                                        />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                            <Table className={classes.table} aria-label="simple table" stickyHeader>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell align="left">Location</TableCell>
                                                                        <TableCell align="left">Host Name</TableCell>
                                                                        <TableCell align="left">Host Email</TableCell>
                                                                        <TableCell align="left">Winner Name</TableCell>
                                                                        <TableCell align="left">Winner Email</TableCell>
                                                                        <TableCell align="left">CVC</TableCell>
                                                                        <TableCell align="left">Certificate</TableCell>
                                                                        <TableCell align="left">Direct Connect</TableCell>
                                                                        <TableCell align="left">Email Flow Started</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {npoCampaigns?.map((c, key) => {
                                                                        return (
                                                                            <TableRow className={tableClass.cell} key={key} onClick={() => {
                                                                                history.push(`/app/campaigns/${c.hash}`)
                                                                            }} >
                                                                                <TableCell component="th" scope="row">{c.name}</TableCell>
                                                                                <TableCell align="left">{c.location?.name}</TableCell>
                                                                                <TableCell align="left">{c.hosts[0]?.name}</TableCell>
                                                                                <TableCell align="left">{c.hosts[0]?.email}</TableCell>
                                                                                <TableCell align="left">{c.winners[0]?.name}</TableCell>
                                                                                <TableCell align="left">{c.winners[0]?.email}</TableCell>
                                                                                <TableCell align="left">{c.isCVC !== 0 ? <CheckIcon /> : ''}</TableCell>
                                                                                <TableCell align="left">{c.hasCertificate !== 0 ? <CheckIcon /> : ''}</TableCell>
                                                                                <TableCell align="left">{c.directConnect !== 0 ? <CheckIcon /> : ''}</TableCell>
                                                                                <TableCell align="left">
                                                                                    {c.emailFlowStarted !== 0 ?
                                                                                        <CheckIcon
                                                                                            classes={{
                                                                                                root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                                                            }}
                                                                                        />
                                                                                        : ''}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Paper>
                                                </Box>
                                            </Grid>
                                        }
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            {hash ? (
                                                <DialogActions style={{ justifyContent: 'flex-start' }}>
                                                    <Button
                                                        onClick={() => setDisplayDeleteDialog(true)}
                                                        color="primary"
                                                    >
                                                        Delete
                                                    </Button>
                                                </DialogActions>
                                            ):null}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DialogActions >
                                                <Button
                                                    onClick={() => { history.goBack() }}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    disabled={form.isSubmitting}
                                                    onClick={() => {
                                                        form.submitForm();
                                                    }}
                                                >
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </form>
                    )
                }
                }
            </Formik >
            <Switch>
                <Route exact={true} path="/app/npos/edit/:hash/contact/:id" component={EditNpoContact} />
                <Route exact={true} path="/app/npos/edit/:hash/contact/" component={EditNpoContact} />
            </Switch>
        </>
    )
}

export default EditNpo;